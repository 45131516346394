import API from '@/service/project-service';

let getToken = ()=>{ return localStorage.getItem('userAccessToken') }

let getAllProject = async (page, limit, filters) =>{
    let params = {
        limit: limit,
        page: page,
        name: filters.name,
        status: filters.status ? filters.status.name : null,
        lastrunon: filters.lastrunon,
        nextrunon: filters.nextrunon
    };
    let headers = { userid  : getToken() };
    try {
        let res = await API.getProjectApi('api/project/getall', headers, params)
        return Promise.resolve(res)
    } catch(err){
        return Promise.reject(err)
    }
}
let createProject = async (projectname) => {
    try {
        var res = await API.postProjectApi('api/project/create',{ name : projectname}, { userid : getToken()});
        return Promise.resolve(res)
    } catch(err){
        return Promise.reject(err)
    }
}
let renameProject = async (projectid, projectname) => {
    var body = {
            'projectid' : projectid,
            'name'      : projectname
    }
    try{
        var res = await API.postProjectApi('api/project/rename', body, {
            userid   : getToken()
        })   
        return Promise.resolve(res);
    }catch(err){
        return Promise.reject(err);
    }
}
let deleteProject = async (projectid) => {;
    try{
        let res = await API.postProjectApi('api/project/delete', { projectid : projectid }, { userid : getToken() }) 
        return Promise.resolve(res)
    }catch(err){
        return Promise.reject(err)
    }
}
let exportProject = async (project) => { ;
    try{
        let res = await API.postProjectApi('api/project/export','', { userid : getToken(), projectid : project }) 
        return Promise.resolve(res)
    }catch(err){
        return Promise.reject(err)
    }
}



export  default { getAllProject , renameProject , deleteProject , exportProject, createProject };
