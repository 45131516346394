<template>
    <div class=content>
        <div class="row mx-0">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header">
                         <h1>Filters</h1>
                    </div>
                    <div class="card-body">
                      <div class="project-title">
                          <h5  data-toggle="collapse" data-target="#collapse1"><i class="fas fa-caret-right"></i>Project</h5>
                          <span @click="filter.name = null">Clear</span>
                      </div>
                      <div class="collapse show" id="collapse1">
                            <div class="prject-container">
                                    <label>Name</label>
                                    <span class="p-input-icon-left ">
                                        <i class="pi pi-search" />
                                        <InputText type="text" class="p-inputtext-sm" placeholder="Search" v-model="filter.name" />
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                      <div class="project-title">
                          <h5 class="collapsed" data-toggle="collapse" data-target="#collapse2"><i class="fas fa-caret-right"></i>Last Run On</h5>
                          <span @click="filter.lastrunon = null">Clear</span>
                      </div>
                      <div class="collapse" id="collapse2">
                            <div class="prject-container">
                                <span class="p-input-icon-left ">
                                    <i class="pi pi-calendar mr-2" />
                                    <Calendar v-model="filter.lastrunon" />
                                </span>
                            </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="project-title">
                          <h5 class="collapsed" data-toggle="collapse" data-target="#collapse3"><i class="fas fa-caret-right"></i>Next Run ON</h5>
                          <span @click="filter.nextrunon = null">Clear</span>
                      </div>
                      <div class="collapse" id="collapse3">
                            <div class="prject-container">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-calendar mr-2" />
                                        <Calendar v-model="filter.nextrunon" />
                                    </span>
                            </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="project-title">
                          <h5 class="collapsed" data-toggle="collapse" data-target="#collapse4"><i class="fas fa-caret-right"></i>Status</h5>
                          <span @click="filter.status = null">Clear</span>
                      </div>
                      <div class="collapse" id="collapse4">
                            <div class="prject-container">
                                <Dropdown v-model="filter.status" :options="statusArray" optionLabel="name" placeholder="Select Status" style="width: 100%"/>
                            </div>
                      </div>
                    </div>
                    <div class="card-footer">
                         <Button label="Filter" @click="filterRun()"/>
                         <Button label="Clear"  @click="filter = {}" class="p-button-outlined" />
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        <h1>Projects</h1> 
                        <Button label="Create New Project" class="p-button-info" @click="projectname = null ;modal.createProjectModal = true"/>
                    </div>
                    <DataTable :value="projects"  responsiveLayout="scroll">
                            <Column field="name" header="Name" :sortable="true" class="project-name" @drag="ok()">
                                <template #body="slotProps">
                                    <span @click = "loadProject(slotProps.data._id)">{{slotProps.data.name}}</span>
                                </template>
                            </Column>
                            <Column field="status" header="STATUS" :sortable="true"></Column>
                            <Column field="lastrunon" header="LAST RUN ON" :sortable="true"></Column>
                            <Column field="nextrunon" header="NEXT RUN ON" :sortable="true"></Column>
                            <Column header="Action"  :sortable="true">
                                <template #body = "slotProps">
                                    <Button title="Rename" type="button" icon="fas fa-edit" class="mr-2 p-button-info"  @click="modal.renameProjectModal = true; projectid = slotProps.data._id; projectname = slotProps.data.name"></Button>
                                    <Button title="Export" type="button" icon="fas fa-file-download" class="mr-2 p-button-info" @click="exportProject(slotProps.data)"></Button>
                                    <Button title="Delete" type="button" icon="fas fa-trash-alt" class="mr-2 p-button-danger" @click="modal.deleteProjectModal = true; projectid = slotProps.data._id; projectname = slotProps.data.name "></Button>
                                </template>
                            </Column> 
                    </DataTable>
                    <Paginator 
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        v-model:first= "page" v-model:rows= "limit" :totalRecords="totalRecords" :rowsPerPageOptions="[5, 10,20,30]" @page="onPage($event)">
                            <template #left="slotProps">
                                Showing {{ ((slotProps.state.first+1) > (totalRecords)) ? totalRecords : slotProps.state.first+1}} to {{((slotProps.state.page+1)*limit > totalRecords) ? totalRecords : (slotProps.state.page+1)*limit}} of {{totalRecords}}
                            </template>  
                    </Paginator>
                </div>
            </div>    
        </div>
    </div>
    <!-- Create Project modal -->
    <Dialog dismissableMask="true" position="top" v-model:visible="modal.createProjectModal" :modal="true" style="max-width:500px; width:100%;">
        <template #header>
            <h3>Create New Project</h3>
        </template>
            <div>
                <InputText class="w-100" type="text" v-model="projectname" placeholder="Project Name" />
            </div>
            <template #footer>
                <Button label="Create" class="p-button-info" @click="createProject()"/>
            </template>
    </Dialog>

    <!-- rename Project modal -->
    <Dialog dismissableMask="true" position="top" v-model:visible="modal.renameProjectModal" :modal="true" style="max-width:500px; width:100%;">
        <template #header>
            <h3>Rename Project</h3>
        </template>
            <div>
                <InputText class="w-100" type="text" v-model="projectname" placeholder="Project Name" v-bind:value="projectname"/>
            </div>
        <template #footer>
            <Button label="Rename" class="p-button-info" @click="renameProject()"/>
        </template>
    </Dialog>

    <!-- `Delete Project Modal -->
      <Dialog dismissableMask="true"  v-model:visible="modal.deleteProjectModal" :style="{width: '350px'}" :modal="true">
            <template #header>
            <h3>Delete Project</h3>
        </template>
            <div class="confirmation-content">
                <span>Are your sure you want to delete this</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="modal.deleteProjectModal = false" class="p-button-info p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="deleteProject()" class="p-button-info p-button-text" autofocus />
            </template>
        </Dialog>

    <div class="loader" id="propertiesLoader" v-if = "loader">
        <ProgressSpinner style="width:50px;height:50px" />
    </div>
</template>
<script>
import method from './all-project'
export default {  
    data(){
        return{
            limit               : 10,
            page                : 0,
            totalRecords        : 0,
            filter : {
                name                : null,
                status              : null,
                lastrunon           : null,
                nextrunon           : null
            },
            checked             : false,
            checked1            : false,
            projects            : null,
            projectname         : null,
            projectid           : null,
            modal : {
                createProjectModal  : false,
                renameProjectModal  : false,
                deleteProjectModal  : false,
            },
            loader              : false,
            statusArray:[
                {name : "RUNNING"},
                {name : "FAILED"}
            ],
            status,
        }
    },
    async mounted() {
        this.projects = await this.getAllProject(this.page, this.limit, this.filter);
    },
    methods: {
        async onPage(event){ 
            this.page = parseInt(event.page) * parseInt(event.rows);
            this.projects = await this.getAllProject(this.page, this.limit, this.filter);
            console.log(this.projects)
        },
        async filterRun(){
            this.projects = await this.getAllProject(this.page, this.limit, this.filter);
        },
        saveFile: function(jsonObj, name) {
            const data = JSON.stringify(jsonObj)
            const blob = new Blob([data], {type: 'text/plain'})
            const e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
            a.download = name+".json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        async getAllProject(page, limit , filter){
            this.loader = true;
            let result = await method.getAllProject(page, limit, filter)
            if (result.status == "OK") {
                this.loader = false
                this.totalRecords = result.totalrecord;
                return result.data; 
            }   
            else{
                this.loader = false
                alert (result.message)
            } 
        },
        async loadProject (projectid){
            this.loader = true;
            this.$router.push({ path: '/project/'+projectid});
        },
        async createProject(){
            this.loader = true;
            let result = await method.createProject(this.projectname);
            if (result.status == 'OK') this.$router.push({ path: '/project/'+result.projectid});
            else{
                this.loader = false
                alert(result.message);
            } 
        },

        async renameProject() {
            this.loader = true
            this.modal.renameProjectModal = false;
            let result = await method.renameProject(this.projectid, this.projectname);
            if (result.status == 'OK') {
                this.loader = false;
                this.projects = await this.getAllProject(this.page, this.limit, this.filter);
            }
            else{
                this.loader = false
                alert(result.message);
            } 
        },
        async deleteProject(){
            this.loader = true;
            this.modal.deleteProjectModal = false;
            let result = await method.deleteProject(this.projectid)
            if (result.status == 'OK') {
                this.projects = await this.getAllProject(this.page, this.limit, this.filter);
                this.loader = false;
            }
            else{
                this.loader = false
                alert(result.message);
            } 
        },
        async exportProject(project){
            this.loader = true ;
            let result = await method.exportProject(project._id)
            if (result.status == 'OK') {
                this.saveFile(result.source, project.name);
                this.loader = false;
            }
            else{
                this.loader = false
                alert(result.message);
            } 
        }
  },
}
</script>
<style lang="scss" scoped>
@import"all-project.scss";
.loader{
    position: fixed;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1500;
}
</style>

